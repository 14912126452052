/** ***************************************************************************
 * Templates / Home page
 *************************************************************************** */

import { pauseAnimations, resumeAnimations } from '../../../src/scripts/utils';

document.addEventListener('DOMContentLoaded', () => {
  const image = document.querySelector('body > header .background img');

  if (image && !image.complete) {
    pauseAnimations();
    image.addEventListener('load', () => {
      resumeAnimations();
    });
  }
});
